/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/require-await */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Partner } from '@quiqupltd/quiqupjs';
import { ApiClient } from '@quiqupltd/quiqupjs';
import { TRACKING_CATEGORIES, trackEvent } from '../globals/analytics';
import { Redux } from '../redux';

export const UserConfigContext = React.createContext({});
export const NOTIFICATIONS_INTERVAL = 1000 * 60 * 60 * 24;

interface Props {
  children: React.ReactNode;
  isPoolingEnabled: boolean;
  userId: number;
}

type segmentationType = {
  messageTitle: string;
  messageBody: string | JSX.Element;
  orderPlacementAllowed: boolean;
};

export type UserConfigResponse = {
  hasOverdueInvoices: boolean;
  pricing: {
    type: string;
    activeFrom: string | null;
    billingPeriod: string | null;
    cashChargePercentage: string | null;
    cardChargePercentage: string | null;
    fixedCardFee: string | null;
    fixedCashFee: string | null;
    extraKgFee: string | null;
    maxKgPerParcel: string | null;
    remoteAreaFee: string | null;
    chargeForRTO: boolean;
    perAdditionalItemFee: string;
    extraKMfee: string;
    pricings: {
      kind: string;
      area: 'same area' | 'different area';
      amount: string;
      upperLimit?: string;
    }[];
  } | null;
  isOrderSubmissionBlocked: boolean;
};

export type notificationType = { segmentation?: segmentationType };

const UserConfigContextProvider = ({ children, isPoolingEnabled, userId }: Props): JSX.Element => {
  const [notifications, setNotifications] = useState<notificationType>({});

  const [userConfig, setUserConfig] = useState<UserConfigResponse>({
    hasOverdueInvoices: false,
    pricing: null,
    isOrderSubmissionBlocked: false,
  });

  useEffect(() => {
    const getUserConfig = async (): Promise<UserConfigResponse> => {
      try {
        const data = await ApiClient.get({ path: `${window.env.INVOICER_API}/user-config` });
        setUserConfig(data);

        return data;
      } catch (error) {
        Sentry.captureMessage('Pooling Notifications', {
          level: Sentry.Severity.Error,
          extra: {
            error,
          },
        });

        return {
          hasOverdueInvoices: false,
          pricing: null,
          isOrderSubmissionBlocked: false,
        };
      }
    };
    getUserConfig();
  }, []);

  useEffect(() => {
    const updateNotifications = async (): Promise<notificationType> => {
      try {
        const notifications: notificationType = await Partner.user.notifications();
        setNotifications(notifications);
        return notifications;
      } catch (error) {
        Sentry.captureMessage('Pooling Notifications', {
          level: Sentry.Severity.Error,
          extra: {
            error,
          },
        });
        return {};
      }
    };

    updateNotifications();

    const interval = setInterval(() => {
      updateNotifications();
    }, NOTIFICATIONS_INTERVAL);

    if (isPoolingEnabled) {
      updateNotifications();
    } else {
      clearInterval(interval);
    }

    return (): void => clearInterval(interval);
  }, [isPoolingEnabled]);

  const handleOverdueLinkClick = (): void => {
    trackEvent({
      action: 'Invoices link click',
      category: TRACKING_CATEGORIES.navigation,
      label: 'overdue banner',
      user: userId,
      location: window.location.pathname,
    });
  };

  useEffect(() => {
    const updateAllNotifications = async (): Promise<void> => {
      try {
        const notifications: notificationType = await Partner.user.notifications();
        const overdueNotification = userConfig.hasOverdueInvoices ? true : false;
        const isBlocked = userConfig.isOrderSubmissionBlocked;
        if (isBlocked) {
          setNotifications({
            segmentation: {
              messageTitle: 'Account Blocked',
              messageBody: (
                <span>
                  Your account is suspended due to overdue payments. Please, clear the debt to reactivate your account.
                  For any queries, please reach out to{' '}
                  <b>
                    <a href="#">dubaifinance@quiqup.com</a>
                  </b>
                </span>
              ),
              orderPlacementAllowed: false,
            },
          });
        } else if (overdueNotification) {
          setNotifications({
            segmentation: {
              messageTitle: 'Invoice Overdue',
              messageBody: (
                <span>
                  Dear Partner, you have overdue payments. Kindly process the payments to avoid service disruptions.
                  Please see outstanding invoices{' '}
                  <a href={`${window.env.SITE_URL}/account/invoices`} onClick={(): void => handleOverdueLinkClick()}>
                    here
                  </a>
                  .
                  <br />
                  If you require assistance, please send an email to{' '}
                  <b>
                    <a href="#">dubaifinance@quiqup.com</a>
                  </b>
                </span>
              ),
              orderPlacementAllowed: false,
            },
          });
        } else {
          setNotifications(notifications);
        }
      } catch (error) {
        Sentry.captureMessage('Pooling Notifications', {
          level: Sentry.Severity.Error,
          extra: {
            error,
          },
        });
      }
    };

    updateAllNotifications();

    const interval = setInterval(() => {
      updateAllNotifications();
    }, NOTIFICATIONS_INTERVAL);

    if (isPoolingEnabled) {
      updateAllNotifications();
    } else {
      clearInterval(interval);
    }

    return (): void => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPoolingEnabled, userConfig, userId]);

  return <UserConfigContext.Provider value={{ notifications, userConfig }}>{children}</UserConfigContext.Provider>;
};

export default connect((state: Redux.State) => ({
  userId: state.user.getIn(['userData', 'id']),
}))(UserConfigContextProvider);
