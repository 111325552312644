import React, { useContext, useEffect, useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import { QuIcon } from '@quiqupltd/QuReactSDK/lib';
import { ReactComponent as Truck } from '../../images/truck.svg';
import { ReactComponent as Analytics } from '../../images/Analytics.svg';
import { ReactComponent as Profile } from '../../images/profile.svg';
import { ReactComponent as Inventory } from '../../images/inventory.svg';
import { ReactComponent as Fulfillment } from '../../images/fulfillment.svg';
import { ReactComponent as Cash } from '../../images/cash.svg';
import { ReactComponent as Code } from '../../images/code.svg';
import { ReactComponent as AngleRight } from '../../images/angle-right.svg';
import { ReactComponent as AngleLeft } from '../../images/angle-left.svg';
import { PRODUCT_SAME_DAY, TRACKING_CATEGORIES, trackEvent } from '../../globals/analytics';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTES } from '../../routing/route';
import {
  CartIcon,
  CollapseButton,
  HelpIcon,
  LogoutIcon,
  Menu,
  MenuContainer,
  PersonalContainer,
  ReplayIcon,
  StyledNavLink,
  WhatsappIcon,
} from './styles';

import { WHATSAPP_URL } from '../../utils/constants';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { ShepherdTourContext } from 'react-shepherd';
import { SidebarStateContext } from '../../contexts/LayoutSettingsContext';
import MenuItem from './menu-item';
import { BusinessAccount } from '../../sameday/pages/company/types';
import { useDispatch } from 'react-redux';
import userActionTypes from '../../redux/modules/user/user.action-types';

const defaultMenuItems = [
  {
    icon: Truck,
    label: 'Orders',
    to: `${ROUTES.SAMEDAY.ORDERS}`,
    testId: 'orders',
    id: 'sidebar-order-link',
    event: 'quqdash_main_menu_orders_link_clicked',
  },
  {
    icon: Fulfillment,
    label: 'Fulfillment',
    isParent: true,
    subItems: [
      {
        label: 'Inventory',
        to: `${ROUTES.SAMEDAY.ORDERS}fulfillment/inventory`,
        icon: Inventory,
        testId: 'fulfillment-inventory',
      },
    ],
    testId: 'fulfillment',
    event: 'quqdash_main_menu_fulfillment_link_clicked',
  },
  {
    icon: Analytics,
    label: 'Analytics',
    to: `${ROUTES.SAMEDAY.ORDERS}reports`,
    testId: 'analytics',
    event: 'quqdash_main_menu_analytics_link_clicked',
  },
  {
    icon: Cash,
    label: 'Finance',
    to: `${ROUTES.SAMEDAY.ORDERS}finance/invoices`,
    testId: 'finance',
    event: 'quqdash_main_menu_finance_link_clicked',
  },
  {
    icon: CartIcon,
    label: 'Pricing',
    to: `${ROUTES.SAMEDAY.RATES}`,
    testId: 'pricing',
    event: 'quqdash_main_menu_pricing_link_clicked',
  },
];

interface SidebarProps {
  userId: number;
  regionSupportNumber: number;
  businessAccounts: BusinessAccount[];
  handleLogout: () => void;
  firstName: string;
  selectedAccount: string;
  userRoles: string[];
}

type ActionMappingKeys = 'whatsapp' | 'manual' | 'support';

const Sidebar: React.FC<SidebarProps> = ({
  userId,
  handleLogout,
  firstName,
  businessAccounts,
  selectedAccount,
  userRoles,
}) => {
  const tour = useContext(ShepherdTourContext);
  const dispatch = useDispatch();
  const { isCollapsed, setIsCollapsed } = useContext(SidebarStateContext);
  const { pathname } = useLocation();
  const { isPhone } = useWindowDimensions();
  const [selected, setSelected] = useState(pathname);
  const [expandedMenus, setExpandedMenus] = useState<Record<string, boolean>>({});
  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  const toggleMenu = (label: string): void => {
    setExpandedMenus({ ...expandedMenus, [label]: !expandedMenus[label] });
  };

  useEffect(() => {
    setSelected(pathname);
  }, [pathname]);

  useEffect(() => {
    const updatedMenuItems = [...defaultMenuItems];
    if (userRoles?.includes('ffintegration')) {
      const fulfillmentMenu = updatedMenuItems.find((item) => item.label === 'Fulfillment');
      const ProductItemExists = fulfillmentMenu?.subItems?.some((item) => item.label === 'Products');

      if (fulfillmentMenu && fulfillmentMenu.subItems && !ProductItemExists) {
        fulfillmentMenu.subItems.push({
          label: 'Products',
          to: `${ROUTES.SAMEDAY.ORDERS}fulfillment/products`,
          icon: Inventory,
          testId: 'fulfillment-products',
        });
      }
    }
    setMenuItems(updatedMenuItems);
  }, [userRoles]);

  useEffect(() => {
    const integrationsItemExists = menuItems.some((item) => item.label === 'Integrations');

    if (!integrationsItemExists) {
      setMenuItems([
        ...menuItems,
        {
          icon: Code,
          label: 'Integrations',
          to: `${ROUTES.SAMEDAY.ORDERS}integrations`,
          testId: 'integrations',
          event: 'quqdash_main_menu_integrations_link_clicked',
        },
      ]);
    }
  }, [menuItems, userRoles]);

  const trackHeaderLinkClick = (target: string): void => {
    if (!target) return;
    if (target === 'Orders' && tour?.isActive()) {
      tour.getById('download-modal')?.show();
      tour?.next();
    }
    trackEvent({
      action: `${target}`,
      category: TRACKING_CATEGORIES.navigation,
      label: target,
      product: PRODUCT_SAME_DAY,
      user: userId,
      location: window.location.pathname,
    });
  };

  const actionMapping: Record<ActionMappingKeys, string> = {
    whatsapp: 'Whatsapp Chat Click',
    manual: 'Quiqdash User Manual Click',
    support: 'Quiqdash Phone Support Hover',
  };

  const trackPersonalLink = (target: ActionMappingKeys): void => {
    const action = actionMapping[target];
    trackEvent({
      action: action,
      category: TRACKING_CATEGORIES.support,
      product: PRODUCT_SAME_DAY,
      user: userId,
      location: window.location.pathname,
    });
  };

  useEffect(() => {
    if (!tour) return;
    const handleTourComplete = (): void => {
      localStorage.setItem('onboarding', 'done');
      trackEvent({
        action: `Quiqdash tour complete`,
        category: TRACKING_CATEGORIES.support,
        product: PRODUCT_SAME_DAY,
        user: userId,
      });
    };

    const handleTourCancel = (): void => {
      localStorage.setItem('onboarding', 'done');
      trackEvent({
        action: `Quiqdash tour canceled`,
        category: TRACKING_CATEGORIES.support,
        product: PRODUCT_SAME_DAY,
        user: userId,
      });
    };
    tour.on('complete', handleTourComplete);
    tour.on('cancel', handleTourCancel);

    return (): void => {
      tour.off('complete', handleTourComplete);
      tour.off('cancel', handleTourCancel);
    };
  }, [tour, userId]);

  const handleReplayOnboardingClick = (): void => {
    tour && tour.start();
  };

  const multipleAccountItem = {
    icon: SwapOutlined,
    label:
      businessAccounts?.length > 0
        ? businessAccounts.filter((acc) => acc.id === selectedAccount)[0]?.name
        : 'Default Label',
    isParent: true,
    subItems: [],
  };

  const handleAccChange = (acc: BusinessAccount): void => {
    dispatch({ type: userActionTypes.SET_BUSINESS_ACCOUNT, payload: acc });
  };

  return (
    <>
      {!isPhone && (
        <Menu isCollapsed={isCollapsed}>
          <MenuContainer>
            <StyledNavLink
              to={ROUTES.SAMEDAY.ORDERS}
              onClick={(): void => trackHeaderLinkClick('Logo')}
              isCollapsed={isCollapsed}
            >
              <QuIcon icon={isCollapsed ? 'q-logo-v2' : 'logo-v2'} height={64} width={isCollapsed ? 33 : 140} />
            </StyledNavLink>
            <CollapseButton onClick={(): void => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed}>
              {isCollapsed ? <AngleRight /> : <AngleLeft />}
            </CollapseButton>

            {menuItems.map((item, index) => {
              if (item.isParent) {
                return (
                  <div key={index}>
                    {businessAccounts?.length > 0 &&
                      businessAccounts.map((acc) => (
                        <div onClick={(): void => handleAccChange(acc)} key={acc.id}>
                          {acc.name}
                        </div>
                      ))}
                    <MenuItem
                      isCollapsed={isCollapsed}
                      label={item.label}
                      icon={item.icon}
                      onClick={(): void => toggleMenu(item.label)}
                      isMain
                      dataTestid={item.testId}
                    />
                    {expandedMenus[item.label] &&
                      item.subItems.map((subItem, subIndex) => (
                        <NavLink
                          key={subIndex}
                          to={subItem.to}
                          activeClassName="is-selected"
                          onClick={(): void => trackHeaderLinkClick(item.event ?? item.label)}
                          data-testid={subItem.testId}
                        >
                          <MenuItem
                            isCollapsed={isCollapsed}
                            label={subItem.label}
                            icon={isCollapsed ? subItem.icon : null}
                            isMain
                            isSelected={subItem.to === selected}
                          />
                        </NavLink>
                      ))}
                  </div>
                );
              } else {
                return item.to ? (
                  <NavLink
                    key={index}
                    to={item.to}
                    activeClassName="is-selected"
                    onClick={(): void => trackHeaderLinkClick(item.event ?? item.label)}
                    data-testid={item.testId}
                  >
                    <MenuItem
                      isCollapsed={isCollapsed}
                      label={item.label}
                      icon={item.icon}
                      isSelected={item.to === selected}
                      isMain
                    />
                  </NavLink>
                ) : null;
              }
            })}
          </MenuContainer>
          <PersonalContainer>
            <NavLink
              to={`${ROUTES.SAMEDAY.ORDERS}account/pickup-addresses`}
              activeClassName="is-selected"
              onClick={(): void => trackHeaderLinkClick('My Account')}
            >
              <MenuItem
                isCollapsed={isCollapsed}
                label={firstName}
                icon={Profile}
                isSelected={`${ROUTES.SAMEDAY.ORDERS}account/pickup-addresses` === selected}
              />
            </NavLink>
            {businessAccounts?.length > 0 && (
              <div>
                <MenuItem
                  isCollapsed={isCollapsed}
                  label={multipleAccountItem.label}
                  icon={multipleAccountItem.icon}
                  onClick={(): void => toggleMenu(multipleAccountItem.label)}
                />
              </div>
            )}
            <NavLink to={`#`} activeClassName="is-selected" onClick={(): void => handleReplayOnboardingClick()}>
              <MenuItem isCollapsed={isCollapsed} label="Tutorial" icon={ReplayIcon} />
            </NavLink>

            <a
              title="Chat on Whatsapp"
              href={WHATSAPP_URL}
              target="_blank"
              rel="noreferrer"
              onClick={(): void => trackPersonalLink('whatsapp')}
              data-test-id="whatsapp-chat"
            >
              <MenuItem isCollapsed={isCollapsed} label="Chat" icon={WhatsappIcon} />
            </a>

            <a
              href={'https://support.quiqup.com'}
              target="_blank"
              rel="noreferrer"
              onClick={(): void => trackPersonalLink('manual')}
            >
              <MenuItem isCollapsed={isCollapsed} label="FAQ" icon={HelpIcon} />
            </a>
            <NavLink to={'#'} onClick={handleLogout}>
              <MenuItem isCollapsed={isCollapsed} label="Logout" icon={LogoutIcon} />
            </NavLink>
          </PersonalContainer>
        </Menu>
      )}
    </>
  );
};
export default Sidebar;
