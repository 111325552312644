import { PARTNER_SAMEDAY } from '../utils/constants';

const BASE_SAMEDAY = '/';

export const ROUTES = {
  SAMEDAY: {
    BASE: BASE_SAMEDAY,
    ORDERS: BASE_SAMEDAY,
    BULK: `${BASE_SAMEDAY}bulk-orders`,
    ORDER: `${BASE_SAMEDAY}order`,
    SINGLE_SHIPMENT: `${BASE_SAMEDAY}single-shipment`,
    REPORTS: `${BASE_SAMEDAY}reports`,
    FULFILLMENT: `https://www.quiqup.com/ecommerce-fulfilment?utm_source=quqdash&utm_campaign=quiqdash_fulfilment&utm_content=`,
    ACCOUNT: `${BASE_SAMEDAY}account`,
    FINANCE: `${BASE_SAMEDAY}finance`,
    INVOICES: `${BASE_SAMEDAY}finance/invoices`,
    COD: `${BASE_SAMEDAY}finance/cod`,
    PICKUP_ADDRESSES: `${BASE_SAMEDAY}account/pickup-addresses`,
    COMPANY: `${BASE_SAMEDAY}account/company`,
    CUT_OFF: `${BASE_SAMEDAY}account/cut-off`,
    RATES: `${BASE_SAMEDAY}account/pricing`,
    INTEGRATIONS: `${BASE_SAMEDAY}/integrations`,
    SHOPIFY_SETTINGS: `${BASE_SAMEDAY}/integrations/shopify-settings`,
  },
};

export function getRootRouteByRole(roles: string[], returnUrl?: string): string {
  return returnUrl ? returnUrl : roles.includes(PARTNER_SAMEDAY) ? ROUTES.SAMEDAY.ORDERS : '';
}
